import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../_metronic/helpers'

const SelectOptions = (props) => {

    const [options, setOptions] = useState(props.options?.length>0 ? props.options : [{ label: '', value: '', default: false }]);

    useEffect(()=>{
        setOptions(props.options?.length>0 ? props.options : [{ label: '', value: '', default: false }])
    },[props.options])
    
    const handleOptionChange = (index, event) => {
      const {name, value}  = event.target;
      const updatedOptions = [...options];
      updatedOptions[index][name] = value;
      setOptions(updatedOptions);
      props.getOptionsData(updatedOptions);
    };
  
    const addOptionField = () => {
        setOptions(options.concat({ label: '', value: '', default: false }));
        props.getOptionsData(options.concat({ label: '', value: '', default: false }));
    };
  
    const removeOptionField = (index) => {        
        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);
        props.getOptionsData(updatedOptions);
    };

    return (
        <>
            {options.map((option, index) => (
            <div key={index}>
                <div className="input-group mb-3">
                    <div className="input-group-text" style={{paddingTop:0, paddingBottom:0}}>
                        <input className="form-check-input mt-0" type="radio" name='default' size={'sm'} onChange={(event) => handleOptionChange(index, event)}/>
                    </div>
                    <input type="text" className="form-control form-control-sm" name='label' onChange={(event) => handleOptionChange(index, event)} value={option.label}/>
                    {index === (options.length - 1) &&
                        <button type="button" className="btn btn-icon btn-light btn-active-light-primary btn-sm me-1" onClick={addOptionField}>
                            <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
                        </button>
                    }

                    {options.length > 1 &&
                        <button type="button" className="btn btn-icon btn-light btn-active-light-danger btn-sm" onClick={() => removeOptionField(index)}>
                            <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
                        </button>
                    }
                </div>
            </div>
           
            ))}
       </>
    );
  };
  
  export default SelectOptions;
  
