/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {getRequestOptions} from './GetToken'
import {postRequestOptions} from '../helpers/Fetchwrapper'
import {Button} from 'react-bootstrap-v5'
import {KTSVG} from '../../_metronic/helpers'
import validationErrorMsg from '../helpers/ValidationErrorMsg'

function ProjectMaster(props) {
  const [isLoading, setIsLoading] = useState(false)

  let history = useHistory()
  const [idProjects, setIdProjects] = useState('')

  const [formData, setFormData] = useState({
    idProjects: '',
    projectName: '',
    projectId: '',
    projectType: 'Limited',
    projectLocation: '',
    details: '',
    status: 'active',
  })

  const handleFormChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const myRef = useRef()

  useEffect(() => {
    if (idProjects !== props.id) {
      setIdProjects(props.id)
      getProjectMasterDetails(props.id)
      window.scrollTo(0, 0)
      myRef.current.focus()
    }
  }, [props.id])

  const getProjectMasterDetails = (idProjects) => {
    fetch(process.env.REACT_APP_API_URL + 'project/' + idProjects, getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        setFormData(resp.data)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  const projectTypes = [
    {label: 'Limited', value: 'Limited'},
    {label: 'Join Venture', value: 'Join Venture'},
    {label: 'Other', value: 'Other'},
  ]

  const statusType = [
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'},
    {label: 'Hidden', value: 'hidden'},
    {label: 'Closed', value: 'closed'},
  ]

  const handleProjectTypeChange = (e) => {
    setFormData({...formData, projectType: e.target.value})
  }

  const handleStatusChange = (e) => {
    setFormData({...formData, status: e.target.value})
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()
    setIsLoading(true)

    const requestOptions = postRequestOptions(formData)

    if (idProjects !== '') {
      var apiEnd = 'project/update/' + idProjects
    } else {
      apiEnd = 'project/store'
    }

    fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        console.log(resp, "resp====")
        setIsLoading(false)
        if (resp.success === true) {
          if (idProjects !== '') {
            var successMsg = [`Project '${resp.data.projectName}' updated`]
          } else {
            successMsg = [`Project '${resp.data.projectName}' added`]
          }

          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: successMsg,
          })
		  
          setIdProjects('')
		  setFormData({
			idProjects: '',
			projectName: '',
			projectId: '',
			projectType: 'Limited',
			projectLocation: '',
			details: '',
			status: 'active',
		  });
          props.onCreate({id: resp.data.idProjects, projectName: resp.data.projectName})
        } else {
			const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
			Swal.fire({
				icon: 'error',
				title: 'Error',
				html: errorsMessage,
			})
          
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error, 'catch the hoop')
      })
  }

  return (
    <div className='card-body'>
      <div className='ml-12'>
        <form onSubmit={handleSubmit}>
          <div className='form-group row'>
            <label
              htmlFor='projectName'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Project Name<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='projectName'
                id='projectName'
                ref={myRef}
                value={formData.projectName}
                onChange={handleFormChange}
                className='form-control form-control-sm'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='projectId'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Project ID<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='projectId'
                id='projectId'
                ref={myRef}
                value={formData.projectId}
                onChange={handleFormChange}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='projectType'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Project Type
            </label>
            <div className='col-lg-5'>
              <select
                className='form-select form-select-sm'
                id='projectType'
                name='projectType'
                value={formData.projectType}
                onChange={handleProjectTypeChange}
              >
                {projectTypes.map(function (item, id) {
                  return (
                    <option key={id} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='projectLocation'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Project Location
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='projectLocation'
                id='projectLocation'
                ref={myRef}
                value={formData.projectLocation}
                onChange={handleFormChange}
                className='form-control form-control-sm'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='details'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Project Details
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='details'
                id='details'
                ref={myRef}
                value={formData.details}
                onChange={handleFormChange}
                className='form-control form-control-sm'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='status'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Status<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <select
                className='form-select form-select-sm'
                id='status'
                name='status'
                value={formData.status}
                onChange={handleStatusChange}
              >
                {statusType.map(function (item, id) {
                  return (
                    <option key={id} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'></div>
            <div className='col-6'>
              <Button disabled={isLoading} variant='success' size='sm' type='submit'>
                {isLoading ? (
                  <i className='fa fa-spin fa-spinner'></i>
                ) : (
                  <>
                    <KTSVG className='svg-icon-3' path='/media/svg/icons/General/Save.svg' />
                    {idProjects !== '' ? 'Update' : 'Save'}
                  </>
                )}
              </Button>
            </div>
          </div>
          {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default ProjectMaster
