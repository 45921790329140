/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {getOptions, postOptions} from '../components/common/ApiService'
import {KTSVG} from '../../_metronic/helpers'
import Select from 'react-select'
import {Modal, Button, Form} from 'react-bootstrap'
import {Col, Row} from 'react-bootstrap-v5'
import SelectOptions from './SelectOptions'
import showLoader from '../helpers/ShowLoader'
import validationErrorMsg from '../helpers/ValidationErrorMsg'

function DocumentMasterSetup(props) {
  let history = useHistory()

  const [type, setType] = useState({label: 'Text', value: 'text'})
  const [fieldData, setFieldData] = useState({
    name: '',
    label: '',
    value: '',
    placeholder: '',
    disabled: false,
    readOnly: false,
    className: '',
  })

  const [fields, setFields] = useState([])
  const [options, setOptions] = useState([{label: '', value: '', default: false}])

  const myRef = useRef()

  useEffect(() => {
    const otherFields = props.data.otherFields ? JSON.parse(props.data.otherFields) : [];
    setFields(otherFields)
  }, [props.data.otherFields])

  const types = [
    {label: 'Text', value: 'text'},
    {label: 'Select', value: 'select'},
    {label: 'Number', value: 'number'},
    {label: 'Date', value: 'date'},
    {label: 'Textarea', value: 'textarea'},
    {label: 'Password', value: 'password'},
  ]

  const handleFormChange = (e) => {
    console.log(e.target.name)
    if (e.target.name === 'label') {
      setFieldData({
        ...fieldData,
        [e.target.name]: e.target.value,
        name: e.target.value.split(' ').join('_').toLowerCase(),
      })
    } else {
      setFieldData({...fieldData, [e.target.name]: e.target.value})
    }
  }

  const handleTypeChange = (selectedOption) => {
    setType(selectedOption)
  }

  const addFields = () => {
    const field = {
      name: fieldData.name + '_' + new Date().getTime(),
      type: type.value,
      options: type.value === 'select' && fieldData.options?.length > 0 ? fieldData.options : [],
      label: fieldData.label,
      value: fieldData.value,
      placeholder: fieldData.placeholder,
      disabled: false,
      readOnly: false,
      className: false,
      validation: fieldData.required ? true : false,
      validations: [
        {
          type: 'required',
          params: [fieldData.label + ' is required'],
        },
      ],
    }
    setFields(fields.concat(field))
  }

  const getOptionsData = (data) => {

    var filterOptions = data.filter(function(option) { 
      return option.label !== '' 
    })

    setFieldData({...fieldData, options: filterOptions})
  }

  const removeField = (index) => {
    const updateFields = [...fields]
    updateFields.splice(index, 1)
    setFields(updateFields)
  }

  const handleFormSubmit = (evt) => {
    evt.preventDefault()
    const postData = {
		idDocMasters: props.data.id,
      	fields: fields,
    }

    var text = 'You want to add this Document Setup!'

    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader()

        fetch(process.env.REACT_APP_API_URL + 'doc-master/setup-document', postOptions(postData))
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            console.log(resp)
            Swal.close()
            if (resp.success === true) {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Document setup successfully',
              })
            } else {
              const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
              Swal.fire({
                icon: 'error',
                title: resp.message,
                html: errorsMessage,
              })
            }
          })
          .catch((error) => {
            Swal.close()
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Something went wrong!',
            })
          })
      }
    })
  }

  return (
    <Modal {...props} size='lg' className='setup-document'>
      <form onSubmit={handleFormSubmit}>
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>{props.data.docName} Setup Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className='border-end' md={6}>
              <Form.Group className='mb-3' controlId='type'>
                <Form.Label>
                  Type<span className='required text-danger'></span>
                </Form.Label>
                <Select
                  onChange={handleTypeChange}
                  options={types}
                  menuPosition='fixed'
                  value={type}
                />
              </Form.Group>
              {type.value === 'select' && (
                <Form.Group className='mb-3' controlId='options'>
                  <Form.Label>Options</Form.Label>
                  <SelectOptions options={options} getOptionsData={getOptionsData} />
                </Form.Group>
              )}
              <Form.Group className='mb-3' controlId='label'>
                <Form.Label>
                  Label<span className='required text-danger'></span>
                </Form.Label>
                <Form.Control
                  onChange={handleFormChange}
                  name='label'
                  type='text'
                  placeholder='Enter Label'
                  size='sm'
                  value={fieldData.label}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='name'>
                <Form.Label>
                  Name<span className='required text-danger'></span>
                </Form.Label>
                <Form.Control
                  name='name'
                  type='text'
                  placeholder='Enter alphanumeric value'
                  size='sm'
                  value={fieldData.name}
                  readOnly
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='placeholder'>
                <Form.Label>Placeholder</Form.Label>
                <Form.Control
                  onChange={handleFormChange}
                  name='placeholder'
                  type='text'
                  placeholder='Enter Placeholder'
                  size='sm'
                  value={fieldData.placeholder}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='class'>
                <Form.Label>Class</Form.Label>
                <Form.Control
                  onChange={handleFormChange}
                  name='class'
                  type='text'
                  placeholder='Enter Class'
                  size='sm'
                  value={fieldData.class}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='value'>
                <Form.Label>Value</Form.Label>
                <Form.Control
                  onChange={handleFormChange}
                  name='value'
                  type='text'
                  placeholder='Enter Value'
                  size='sm'
                  value={fieldData.value}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='required'>
                <Form.Label className='mx-2'>Required</Form.Label>
                <Form.Check
                  inline
                  name='required'
                  type={'checkbox'}
                  id={`inline-required`}
                  size={'sm'}
                  value={fieldData.required ? 1 : 0}
                  checked={fieldData.required}
                />
              </Form.Group>
              <div className='d-flex justify-content-end px-3 pb-2'>
                <Button onClick={addFields} variant='success' size='sm'>
                  <KTSVG className='svg-icon-3' path='/media/svg/icons/Code/Plus.svg' /> Add
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <p>View Form:</p>
              {fields.map((item, index) => (
                <Form.Group className='mb-3' controlId={item.name} key={'form-' + index}>
                  <Form.Label>
                    {item.label}
                    {item.validation && <span className='required text-danger'></span>}
                    <button
                      type='button'
                      className='btn btn-icon btn-light btn-active-light-danger btn-sm'
                      onClick={() => removeField(index)}
                      size={'sm'}
                      style={{height: 'calc(0.5em + 1.1rem + 2px)'}}
                    >
                      <KTSVG
                        path='/media/svg/icons/General/Trash.svg'
                        className='svg-icon svg-icon-5'
                      />
                    </button>
                  </Form.Label>
                  {item.type === 'select' ? (
                    <Form.Select
                      name={item.name}
                      className={item.class}
                      size='sm'
                      aria-label='Select'
                    >
                      {item.options.map((option, index) => (
                        <option key={'option-' + index} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name={item.name}
                      type={item.type}
                      className={item.class}
                      placeholder={item.placeholder}
                      size='sm'
                    />
                  )}
                </Form.Group>
              ))}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='py-1'>
          <Button type='submit' variant='success' className='btn-sm me-2'>
            <span> Save</span>
          </Button>
        </Modal.Footer>
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre>
        <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </form>
    </Modal>
  )
}

export default DocumentMasterSetup
