/* eslint-disable react-hooks/exhaustive-deps */
import {useContext,useState,useEffect} from "react";
import {userPermissionContext} from '../context/UserPagePermission';

function UserPagePermission(value){
    const {pagesLink,loading} = useContext(userPermissionContext);
    const [hasPermission, setPermission] = useState(1);
    
    useEffect(() => {
        const permission = pagesLink.filter(function(el) {
            return (el.pagesLink === value);
              
          });
          
          if(permission.length > 0){
              setPermission(1)
          }
          else if(loading === false){
            setPermission(0)
          }

    } , [pagesLink]);

 
    return hasPermission;

}

export default UserPagePermission;
