/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Switch, Route} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import Logout from "../pages/Logout";


const Routes: FC = () => {

	var redirect_url:any = process.env.REACT_APP_URL;
	redirect_url = btoa(redirect_url);
	redirect_url = encodeURIComponent(redirect_url);
	
	function getCookie(name:string) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()!.split(';').shift();
    }

    const jwt = { token: getCookie('MyToken') };
	//console.log(jwt);

	return (
		<Switch>

		<Route path="/error" component={ErrorsPage}/>
		<Route path="/logout" component={Logout}/>

		{ (!jwt || jwt['token']===undefined ) ? (
			/* Redirect to `/auth` when user is not authorized */
			<Route path='/' component={() => { 
				window.location.href = process.env.REACT_APP_LOGIN + 'login/index/'+ redirect_url; 
				return null;
			}}/>
			) 
			:(
			<MasterLayout>
			<PrivateRoutes />
			</MasterLayout>
			)}
		</Switch>
  	);
}
export {Routes}