import React, { useState, useEffect } from "react";

export const DocumentHistory = (props) => {

	const [documentStatus, setDocumentStatus] = useState([]);
	const [documentNo, setDocumentNo] = useState('');

	useEffect(() => {
		if (props.documentNo !== undefined) {
			setDocumentStatus(props.documentStatus)
			setDocumentNo(props.documentNo)
		}
	}, [props.documentNo, props.documentStatus])

	return (
		<div className='timeline-items'>
			{documentStatus.map((item, index) => (
				<div className='timeline-item' key={index}>
					<div className='timeline-media'>
						{item.status === 'created' && <i className='fa fa-plus text-primary'></i>}
						{item.status === 'approved' && <i className='fa fa-user text-info'></i>}
						{item.status === 'amendment' && <i className='fa fa-arrow-right text-primary'></i>}
						{item.status === 'amendment_approved' && <i className='fa fa-user text-info'></i>}
						{item.status === 'amendment_deleted' && <i className='fa fa-trash text-danger'></i>}
						{item.status === 'closed' && <i className='fa fa-check text-success'></i>}
						{item.status === 'modified' && <i className='fa fa-pen text-warning'></i>}
					</div>
					<div className='timeline-content'>
						<div className='d-flex align-items-center justify-content-between mb-3'>
							<div className='mr-4'>
								<span
									className='mr-3 font-weight-bolder'
									style={{ fontSize: '15px', color: '#e35b5a' }}
								>
									Document No {documentNo} has been
									{item.status === 'created' && ' Created'}
									{item.status === 'approved' && ' Approved'}
									{item.status === 'amendment' && ' Amendment'}
									{item.status === 'amendment_approved' && ' Amendment Approved'}
									{item.status === 'amendment_deleted' && ' Amendment Deleted'}
									{item.status === 'closed' && ' Closed'}
									{item.status === 'modified' && ' Modified'}
									<span className='text-muted ml-2'> at {item.createDate}</span>
								</span>
								{item.status === 'created' && (
									<>
										<p className='mr-3'>
											Created By :{' '}
											<a href='/#'>
												<b>{item.username}</b>
											</a>
										</p>
									</>
								)}
								{item.status === 'approved' && (
									<>
										<p className='mr-3'>
											Approved By :{' '}
											<a href='/#'>
												<b>{item.username}</b>
											</a>
										</p>
									</>
								)}
								{item.status === 'amendment' && (
									<>
										<p className='mr-3'>
											Amendment By :{' '}
											<a href='/#'>
												<b>{item.username}</b>
											</a>
										</p>
									</>
								)}
								{item.status === 'amendment_approved' && (
									<>
										<p className='mr-3'>
											Amendment Approved By :{' '}
											<a href='/#'>
												<b>{item.username}</b>
											</a>
										</p>
									</>
								)}
								{item.status === 'amendment_deleted' && (
									<>
										<p className='mr-3'>
											Amendment Deleted By :{' '}
											<a href='/#'>
												<b>{item.username}</b>
											</a>
										</p>
									</>
								)}
								{item.status === 'closed' && (
									<>
										<p className='mr-3'>
											Closed By :{' '}
											<a href='/#'>
												<b>{item.username}</b>
											</a>
										</p>
									</>
								)}
								{item.status === 'modified' && (
									<>
										<p className='mr-3'>
											Modified By :{' '}
											<a href='/#'>
												<b>{item.username}</b>
											</a>
										</p>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}


export default DocumentHistory;
