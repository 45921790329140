import React, {useState} from 'react'
import DataTable from '../components/DataTable'
import {KTSVG} from '../../_metronic/helpers'
import ProjectMaster from '../components/ProjectMaster'

export const Project = () => {
  const [newData, setNewData] = useState('')
  const [id, setId] = useState('')

  const onCreate = ({id, projectName}) => {
    setNewData({id: id, projectName: projectName})
  }

  const columns = [
    {value: 'idProjects', title: 'Id', style: {textAlign: 'center'}},
    {value: 'projectName', title: 'Project Name'},
    {value: 'projectId', title: 'Project ID'},
    {value: 'projectType', title: 'Project Type'},
    {value: 'projectLocation', title: 'Project Location'},
    {value: 'details', title: 'Details'},
    {
			value: 'status', title: 'Status', 
      style: { textAlign: 'center' }, 
      hasHtmlComponent: true, 
      htmlComponent: ((row) => {
        // return console.log(row)
				return (
          
					<>
						{row.status === 'active' && <span className="badge badge-light-primary">Active</span>}
						{row.status === 'inactive' && <span className="badge badge-light-danger">Inactive</span>}
						{row.status === 'closed' && <span className="badge badge-light-success">Closed</span>}
						{row.status === 'hidden' && <span className="badge badge-light-success">Hidden</span>}
					</>
				)
			})
		},
    {
			value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row) => {
				return (
					<>
						<button className='btn btn-light-primary btn-sm' onClick={() => onAction(row)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-5' />
              Edit
            </button>
					</>
				)
			})
		}
  ]

  const filters = {
    idProjects: {
      filterType: 'like',
      filterValue: '',
    },
    projectName: {
      filterType: 'like',
      filterValue: '',
    },
    projectId: {
      filterType: 'like',
      filterValue: '',
    },
    projectType: {
      filterType: 'like',
      filterValue: '',
    },
    projectLocation: {
      filterType: 'like',
      filterValue: '',
    },
    details: {
      filterType: 'like',
      filterValue: '',
    },
    status: {
      filterType: 'like',
      filterValue: '',
    },
  }

  const url = process.env.REACT_APP_API_URL + 'projects'

  const onAction = (data) => {
    //console.log(data)
    setId(data.idProjects)
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Project Master
            </span>
          </div>
        </div>

        <div className='card-body'>
          <ProjectMaster onCreate={onCreate} id={id} />
          <DataTable url={url} columns={columns} filters={filters} newData={newData} />
        </div>
      </div>
    </>
  )
}

export default Project
