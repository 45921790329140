import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {Tab, Tabs} from 'react-bootstrap-v5'
import AsyncSelect from 'react-select/async'
import {getRequestOptions} from '../components/GetToken'
import {postRequestOptions} from '../helpers/Fetchwrapper'
import Swal from 'sweetalert2'

function UserRolePermission() {
  const initialState = () => ({
    idUsers: '',
    idRoles: '',
    user: '',
  })
  const [data, setData] = useState(initialState())
  const [roleInfo, setRoleInfo] = useState([])

  useEffect(() => {
    if (data.idUsers !== '') {
      getRole(data.idUsers)
    }
  }, [data.idUsers])

  useEffect(() => {
    if (data.idRoles !== '') {
      assignRole()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.idRoles])

  const getUser = (inputValue, callback) => {
    if (!inputValue) {
      callback([])
    } else {
      var url = process.env.REACT_APP_API_URL + 'userSearch?q=' + inputValue
      setTimeout(() => {
        fetch(url, getRequestOptions())
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            const tempArray = resp.data.map(function (element) {
              element.label = `${element.fullName} (${element.email})`
              element.value = element.idUsers
              return element
            })
            callback(tempArray)
          })
          .catch((error) => {
            console.log(error, 'catch the hoop')
          })
      })
    }
  }

  const handleUserChange = (user) => {
    setData({...data, user: user, idUsers: user.idUsers})
  }

  const getRole = (idUsers) => {
    fetch(process.env.REACT_APP_API_URL + 'getUserRoles/' + idUsers, getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        setRoleInfo(resp.data)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  const onRoleChange = (index, idRoles) => {
    const newItems = roleInfo.map(function (row, rindex) {
      if (index === rindex) {
        if (row.isPermission === 0) {
          row.isPermission = 1
        } else {
          row.isPermission = 0
        }
      }
      return row
    })
    setRoleInfo(newItems)
    setData({...data, idRoles: idRoles})
  }

  const assignRole = () => {
    const jwt = () => {
      return JSON.parse(localStorage.getItem('MyToken'))
    }
    const formData = {...data}
    const requestOptions = postRequestOptions(formData)
    fetch(process.env.REACT_APP_API_URL + 'setUserRolePermission', requestOptions)
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        console.log(resp)

        if (resp.success === true) {
          Swal.fire({
            position: 'top-right',
            icon: 'success',
            title: 'User Role Changed',
            showConfirmButton: false,
            timer: 1500,
          })
          setData({...data, idRoles: ''})
        } else {
          var errorsMessage = []

          if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
            var errorsObj = resp.errorMessage
            Object.keys(errorsObj).forEach(function (value) {
              errorsObj[value].forEach(function (v) {
                errorsMessage.push(v)
              })
            })
          } else if (
            resp.errorMessage !== undefined &&
            (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)
          ) {
            errorsMessage.push(resp.errorMessage)
          } else {
            errorsMessage.push('Something went wrong')
          }
          Swal.fire({
            icon: 'error',
            title: resp.heading,
            text: errorsMessage,
          })
        }
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/social/soc002.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              USER ROLE PERMISSION
            </span>
          </div>
        </div>

        <div className='ml-12'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group row'>
                  <AsyncSelect
                    value={data.user}
                    defaultOptions
                    loadOptions={getUser}
                    placeholder='Select User'
                    onChange={handleUserChange}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                {data.idUsers !== '' && (
                  <>
                    <div>
                      <KTSVG
                        path='/media/svg/icons/General/User.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />{' '}
                      User : {data.user.fullName}
                    </div>
                    <div className='mt-1'>
                      <KTSVG
                        path='/media/svg/icons/Clothes/Briefcase.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />{' '}
                      Designation : {data.user.designation}
                    </div>
                    <div className='mt-1'>
                      <KTSVG
                        path='/media/svg/icons/Devices/Phone.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />{' '}
                      Phone : {data.user.phoneNumber}
                    </div>
                    <div className='mt-1'>
                      <KTSVG
                        path='/media/svg/icons/Communication/Mail.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />{' '}
                      Email : {data.user.email}
                    </div>
                  </>
                )}
              </div>
              {data.idUsers !== '' && (
                <div className='mt-10'>
                  <div className='card-body'>
                    <table className='table table-striped table-bordered'>
                      <thead>
                        <tr className='text-primary' style={{textAlign: 'center'}}>
                          <th>Role</th>
                          <th>Permission</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleInfo.map((value, index) => (
                          <tr key={index}>
                            <td className='text-center'>{value.roleName}</td>
                            <td
                              style={{padding: '0.75rem', textAlign: 'center'}}
                              onClick={() => onRoleChange(index, value.idRoles)}
                            >
                              {value.isPermission === 1 && (
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr012.svg'
                                  className='svg-icon-lg svg-icon-success'
                                />
                              )}
                              {value.isPermission === 0 && (
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr011.svg'
                                  className='svg-icon-lg svg-icon-danger'
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <pre>{JSON.stringify(roleInfo, null, 2)}</pre> */}
        </div>
      </div>
    </>
  )
}
export default UserRolePermission
