import React, { useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';
import { Tab, Tabs } from "react-bootstrap-v5";
import PageAssign from '../components/PageAssign';
import RoleCreate from '../components/RoleCreate';
import RoleDt from '../components/RoleDt';
import { Redirect } from "react-router-dom";
import Error500 from '../components/Error500';

function RoleManagement() {

    const [id, setId] = useState("");
    const [newRole, setNewRole] = useState([]);

    const onCreate = (newRole) => {
        setNewRole(newRole);
    }

    const onAction = (id) => {
        setId(id);
    }

    return (
        <>
        
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <KTSVG
                                path='/media/svg/icons/Code/Info-circle.svg'
                                className='svg-icon-1 svg-icon-primary'
                            />
                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                ROLE MANAGEMENT
                            </span>
                        </div>
                    </div>

                    <div className="ml-12">
                        <div className="card-body">
                            <Tabs defaultActiveKey="createRole" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="createRole" title="Role Create">
                                    <RoleCreate onCreate={onCreate} id={id} />
                                    <div className="card-header">
                                        <div className="card-title">
                                            <KTSVG
                                                path='/media/svg/icons/Text/Bullet-list.svg'
                                                className='svg-icon-1 svg-icon-primary'
                                            />
                                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                                ROLE LIST
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <RoleDt addNewRole={newRole} onAction={onAction} />
                                    </div>
                                </Tab>
                                <Tab eventKey="pageAssign" title="Assign Page">
                                    <PageAssign />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
  
        </>
    )
}

export default RoleManagement;