import React, {useState, useEffect, useContext} from 'react'
import Swal from 'sweetalert2'
import {KTSVG} from '../../_metronic/helpers'
import {getOptions, postOptions} from '../components/common/ApiService'
import showLoader from '../helpers/ShowLoader'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import AsyncSelect from 'react-select/async'
import Button from '@mui/material/Button';
import { AppContext } from '../context/AppContext'
import { Link } from "react-router-dom";


export const BuWiseDocument = () => {
  const [documents, setDocuments] = useState([])
  const [project, setProject] = useState('')

  const {capitalizeFirst} = useContext(AppContext)

  const getBusinessUnits = (inputValue, callback) => {

    if(!inputValue){
        var api =  process.env.REACT_APP_API_URL + 'get_business_units';
    }
    else{
        api = process.env.REACT_APP_API_URL + 'get_business_units?q=' + inputValue
    }
    setTimeout(() => {
      fetch(api, getOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {
              ...element,
              id: element.idProjects,
              label: element.projectName,
              value: element.idProjects,
            }
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    }, 1000)
  }

  const getAssignedDocuments = (idProjects) => {
    showLoader()
    var api = process.env.REACT_APP_API_URL + 'report/bu-wise-assigned-document/' + idProjects
    fetch(api, getOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        Swal.close()
        setDocuments(resp.data)
      })
      .catch((error) => {
        Swal.close()
        console.log(error, 'catch the hoop')
        
      })
  }

  const handleProjectChange = (project) => {
    getAssignedDocuments(project.idProjects)
    setProject(project)
  }

 
  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Business Unit Wise Documents
            </span>
          </div>
        </div>

        <div className='card-body'>
          <div className='form-group row'>
            <label
              htmlFor='idProjects'
              className='col-lg-4 col-form-label'
              style={{textAlign: 'right'}}
            >
              Business Unit<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <AsyncSelect
                value={project}
                name='idProjects'
                id='idProjects'
                defaultOptions
                loadOptions={getBusinessUnits}
                placeholder='Select Business Unit'
                onChange={handleProjectChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mt-5'>
              <table className='table table-bordered table-hover'>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Document Name</th>
                    <th className='text-center'>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.docName}</td>
                      <td className='text-center'>
                        {(() => {
                          if (item.documents.length>0) {
                            return (
                              <span className="badge badge-success"><i className='fa fa-check text-white'></i></span>
                            )
                          } else {
                            return (
                              <span className="badge badge-danger"><i className='fa fa-window-close text-white'></i></span>
                            )
                          }
                        })()}
                      </td>
                      <td>
                        {item.documents.length>0 && 
                          <Link to={`/document-details/${item.documents[0].idDocDocuments}`}>
                              <Button variant="contained" size='small' className='me-2'><i className='fa fa-list me-2 text-white'></i>  
                              Details</Button>
                          </Link>
                        }
                        {item.documents.length===0 && 
                        <Link to={`/document-create`}>
                            <Button variant="contained" size='small' className='me-2'><i className='fa fa-plus me-2 text-white'></i> 
                            Add</Button>
                        </Link>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BuWiseDocument
