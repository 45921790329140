import React, {Suspense} from 'react'
import {HashRouter } from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {Routes} from './routing/Routes'
import AppContextProvider from './context/AppContext';
import UserPagePermission from './context/UserPagePermission';


type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <HashRouter   basename={basename}>
        <I18nProvider>
          <AppContextProvider>
          <UserPagePermission>
          <LayoutProvider>
              <Routes />
          </LayoutProvider>
          </UserPagePermission>
          </AppContextProvider>
        </I18nProvider>
      </HashRouter  >
    </Suspense>
  )
}

export { App }

