/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  getOptions,
  postFileOptions,
  postOptions,
  deleteOptions,
} from '../components/common/ApiService'
import {KTSVG} from '../../_metronic/helpers'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import showLoader from '../helpers/ShowLoader'
import {Button, Modal} from 'react-bootstrap-v5'
import { Link } from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import axios from 'axios'
import DocumentHistory from '../components/DocumentHistory'
import UserPagePermission from '../components/UserPagePermission'

function DocumentDetails(props) {
  const [details, setDetails] = useState({})

  let history = useHistory()
  const [dynamicFields, setDynamicFields] = useState([])
  const hasDeletePermission = UserPagePermission('/document-delete')
  const hasApprovePermission = UserPagePermission('/document-approve')
  const hasDocFileUploadPermission = UserPagePermission('/document-file-upload')
  const hasDocFileDeletePermission = UserPagePermission('/document-file-delete')
  const filehost = process.env.REACT_APP_AWS_URL + 'document_portal/document'

  useEffect(() => {
    getDocumentDetails(props.match.params.id)
  }, [])

  const getDocumentDetails = (documentId) => {
    showLoader()
    fetch(process.env.REACT_APP_API_URL + 'document/' + documentId, getOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        Swal.close()
        setDetails(resp)
        let dynamicFields = []
        if (resp.document_master.otherFields !== undefined) {
          dynamicFields = JSON.parse(resp.document_master.otherFields)
        }
        setDynamicFields(dynamicFields)
      })
      .catch((error) => {
        Swal.close()
        console.log(error, 'catch the hoop')
      })
  }

  const removeFile = (id) => () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ec008c',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.value) {
        showLoader()

        fetch(process.env.REACT_APP_API_URL + 'document/file-delete/' + id, deleteOptions())
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            Swal.close()
            const successMsg = resp.successMessage
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: successMsg,
            })
            getDocumentDetails(props.match.params.id)
          })
          .catch((error) => {
            Swal.close()
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  const approve = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ec008c',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Approve it!',
    }).then((result) => {
      if (result.value) {
        showLoader()
        const formData = {
          documentId: props.match.params.id,
        }
        const documentOptions = postOptions(formData)

        fetch(process.env.REACT_APP_API_URL + 'document/approve', documentOptions)
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            Swal.close()
            if (resp.success === true) {
              const successMsg = resp.successMessage
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMsg,
              })
              getDocumentDetails(props.match.params.id)
            } else {
              const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
              Swal.fire({
                icon: 'error',
                title: 'Error',
                html: errorsMessage,
              })
            }
          })
          .catch((error) => {
            Swal.close()
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  const deleteDocument = () => {
    let id = props.match.params.id
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this document!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ec008c',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.value) {
        showLoader()

        fetch(process.env.REACT_APP_API_URL + 'document/delete/' + id, deleteOptions())
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            Swal.close()
            const successMsg = resp.successMessage
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: successMsg,
            }).then(function () {
              history.push(`/document-list`)
            })
          })
          .catch((error) => {
            Swal.close()
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  const handleSubmit = (files, allFiles) => {
    showLoader()

    const postFormData = new FormData()
    postFormData.append('idDocDocuments', props.match.params.id)
    for (let i = 0; i < files.length; i++) {
      postFormData.append('files[]', files[i].file)
    }

    var url = process.env.REACT_APP_API_URL + 'document/file-upload'
    axios(postFileOptions(url, postFormData))
      .then((response) => {
        Swal.close()
        const successMessage = response.data.successMessage
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: successMessage,
        })

        allFiles.forEach((f) => f.remove())
        getDocumentDetails(props.match.params.id)
      })
      .catch((error) => {
        Swal.close()
        const errorsMessage = validationErrorMsg(error.response.data.errors).join('<br>')
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: errorsMessage,
        })
      })
  }

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <div className='card-title'>
          <KTSVG
            path='/media/svg/icons/Code/Info-circle.svg'
            className='svg-icon-1 svg-icon-primary'
          />
          <span className='text fw-bolder' style={{marginLeft: '10px'}}>
            Document Details
          </span>
        </div>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-6'>
            <table className='table table-bordered table-hover'>
              <tbody>
                <tr>
                  <th>Business Unit</th>
                  <td>{details?.project?.projectName}</td>
                </tr>
                <tr>
                  <th>Document</th>
                  <td>{details?.document_master?.docName}</td>
                </tr>
                <tr>
                  <th>Document No</th>
                  <td>{details?.documentNo}</td>
                </tr>
                <tr>
                  <th>Issue Date</th>
                  <td>{details?.issueDate}</td>
                </tr>
                <tr>
                  <th>Expire Date</th>
                  <td>{details?.expireDate}</td>
                </tr>
                <tr>
                  <th>Document Fee</th>
                  <td>{details?.documentFee}</td>
                </tr>
                <tr>
                  <th>Document Location</th>
                  <td>{details?.documentLocation}</td>
                </tr>
                {dynamicFields?.map((field, i) => {
                  let otherData = JSON.parse(details.otherFields)
                  return (
                    <tr key={i}>
                      <th>{field?.label}</th>
                      <td>{otherData[field.name]}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='col-6'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>Document history</div>
              </div>

              <div className='card-body'>
                <div className='timeline timeline-3' style={{maxHeight: '500px', overflow: 'auto'}}>
                  <DocumentHistory
                    documentStatus={details.status_history}
                    documentNo={details.documentNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {details?.amendment && (
        <>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>Amendment History</div>
          </div>
          
          <div className='card-body' >
            <div className='row mt-2'>
              <div className='col-12'>
                <table className='table table-bordered table-hover'>
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Amendment Type</th>
                      <th>Amendment Date</th>
                      <th>Amendment No</th>
                      <th>Document No</th>
                      <th>Issue Date</th>
                      <th>Expire Date</th>
                      <th>Document Fee</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details?.amendment?.map((item, index) => (
                      <tr key={'amendment-'+index}>
                        <td>{index + 1}</td>
                        <td>{item.amendmentType}</td>
                        <td>{item.amendmentDate}</td>
                        <td>{item.amendmentNo}</td>
                        <td>{item.documentNo}</td>
                        <td>{item.issueDate}</td>
                        <td>{item.expireDate}</td>
                        <td>{item.documentFee}</td>
                        <td>
                          <Link to={`/document-amendment-details/${item.id_doc_document_amendments}`}>
                              <Button variant="primary" size='sm' className='me-2'><i className='fa fa-list text-white'></i> Amendment Details</Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </>
        )}

        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>Files</div>
          </div>

          <div className='card-body'>
            <div className='row mt-2'>
              <div className='col-6'>
                <table className='table table-bordered table-hover'>
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>File</th>
                      <th>Size</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {details?.files?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <a
                            href={`${filehost}/${item.fileName}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-decoration-none'
                          >
                            {item.originalFile}
                          </a>
                        </td>
                        <td>{(item.fileSize / 1024 / 1024).toFixed(2)} MB</td>
                        <td>
                          {hasDocFileDeletePermission === 1 && (
                            <button
                              type='button'
                              className='btn btn-icon btn-light btn-active-light-danger btn-sm'
                              onClick={removeFile(item.id_doc_files)}
                            >
                              <KTSVG
                                path='/media/svg/icons/General/Trash.svg'
                                className='svg-icon svg-icon-5'
                              />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='col-6'>
                {hasDocFileUploadPermission === 1 && (
                  <Dropzone
                    accept='image/*,audio/*,application/pdf'
                    onSubmit={handleSubmit}
                    // inputContent="Attachment Files"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          {details?.docStatus === 'created' && (
            <>
              <div className='col-12 text-center'>
                {hasApprovePermission === 1 && (
                  <button
                    type='primary'
                    className='btn btn-sm btn-primary me-2'
                    onClick={() => approve()}
                  >
                    <span>
                      <i className='fa fa-check'></i> Approve
                    </span>
                  </button>
                )}
                {hasDeletePermission === 1 && (
                  <Button variant='danger' size='sm' onClick={() => deleteDocument()}>
                    <i className='fa fa-trash'></i> Delete
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default DocumentDetails
