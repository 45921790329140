//Get token from cookie
const getCookie =  (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

const getToken =  () => {
	return  getCookie('MyToken');
};

const getOptions = () => {
	let requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
	};
	return requestOptions;
}

const postOptions = (body) => {
	let requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json','Accept': 'application/json' ,'Authorization': 'Bearer ' + getToken() },
		body: JSON.stringify(body)
	};
	return requestOptions;
}


const postDtOptions = (body) => {
	let requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json','Accept': 'application/json' ,'Authorization': 'Bearer ' + getToken() },
		body: JSON.stringify(body)
	};
	return requestOptions;
}

const postFileOptions =  (url,body) => {
	// console.log(getToken());
	let token =   getToken();
	let requestOptions = {
		url: url,
		method: 'post',
		headers: { 'Content-Type': 'multipart/form-data','Authorization': 'Bearer ' + token},
		data: body
	};
	return requestOptions;
}

const deleteOptions = () => {
	let requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
	};
	return requestOptions;
}



export {getToken, getOptions, postOptions, postDtOptions, postFileOptions, deleteOptions };



