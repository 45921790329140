/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import { Link } from "react-router-dom";
import { getOptions, postOptions } from '../components/common/ApiService'
import Button from '@mui/material/Button';
import UserPagePermission from  '../components/UserPagePermission'

const Dashboard = () => {
  
  const columns = [
		{ value: 'idDocDocuments', title: 'Id', style: { textAlign: 'center' } },
    { value: 'projectName', title: 'Business Unit', style: { textAlign: 'left' } },
    { value: 'document', title: 'Document', style: { textAlign: 'left' } },
		{ value: 'documentNo', title: 'Document No', style: { textAlign: 'left' } },
		{ value: 'issueDate', title: 'Issue Date', style: { textAlign: 'left' } },
		{ value: 'expireDate', title: 'Expire Date', style: { textAlign: 'left' } },
        { value: 'documentFee', title: 'Fee', style: { textAlign: 'left' } },
        { value: 'documentLocation', title: 'Location', style: { textAlign: 'left' } },
		{
			value: 'status', title: 'Status', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						{row.status === 'created' && <span className="badge badge-light-primary">Created</span>}
						{row.status === 'approved' && <span className="badge badge-light-info">Approved</span>}
						{row.status === 'closed' && <span className="badge badge-light-success">Closed</span>}
						{row.status === 'amendment' && <span className="badge badge-light-success">Amendment</span>}
					</>
				)
			})
		},
		{
			value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row) => {
				return (
					<>
						<Link to={`/document-details/${row.idDocDocuments}`}>
                            <Button variant="contained" size='small' className='me-2'><i className='fa fa-list me-2 text-white'></i>  
								Details</Button>
						</Link>
					</>
				)
			})
		}
	]

	const filters = {
		idDocDocuments: {
			filterType: 'like',
			filterValue: '',
		},
        projectName: {
			filterType: 'like',
			filterValue: '',
		},
		documentNo: {
			filterType: 'like',
			filterValue: '',
		},
		document: {
			filterType: 'like',
			filterValue: '',
		},
		issueDate: {
			filterType: 'like',
			filterValue: '',
		},
		expireDate: {
			filterType: 'like',
			filterValue: '',
		},
        documentLocation: {
			filterType: 'like',
			filterValue: '',
		},
        documentFee: {
			filterType: 'like',
			filterValue: '',
		},
		status: {
			filterType: 'like',
			filterValue: '',
		}

	}

	const url = process.env.REACT_APP_API_URL + 'dashboard/expired-documents'

  const columnsNext = [
		{ value: 'idDocDocuments', title: 'Id', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Business Unit', style: { textAlign: 'left' } },
        { value: 'document', title: 'Document', style: { textAlign: 'left' } },
		{ value: 'documentNo', title: 'Document No', style: { textAlign: 'left' } },
		{ value: 'issueDate', title: 'Issue Date', style: { textAlign: 'left' } },
		{ value: 'expireDate', title: 'Expire Date', style: { textAlign: 'left' } },
        { value: 'documentFee', title: 'Fee', style: { textAlign: 'left' } },
        { value: 'documentLocation', title: 'Location', style: { textAlign: 'left' } },
		{
			value: 'status', title: 'Status', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						{row.status === 'created' && <span className="badge badge-light-primary">Created</span>}
						{row.status === 'approved' && <span className="badge badge-light-info">Approved</span>}
						{row.status === 'closed' && <span className="badge badge-light-success">Closed</span>}
						{row.status === 'amendment' && <span className="badge badge-light-success">Amendment</span>}
					</>
				)
			})
		},
		{
			value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row) => {
				return (
					<>
						<Link to={`/document-details/${row.idDocDocuments}`}>
                            <Button variant="contained" size='small' className='me-2'><i className='fa fa-list me-2 text-white'></i>  
								Details</Button>
						</Link>
					</>
				)
			})
		}
	]

	const filtersNext = {
		idDocDocuments: {
			filterType: 'like',
			filterValue: '',
		},
        projectName: {
			filterType: 'like',
			filterValue: '',
		},
		documentNo: {
			filterType: 'like',
			filterValue: '',
		},
		document: {
			filterType: 'like',
			filterValue: '',
		},
		issueDate: {
			filterType: 'like',
			filterValue: '',
		},
		expireDate: {
			filterType: 'like',
			filterValue: '',
		},
        documentLocation: {
			filterType: 'like',
			filterValue: '',
		},
        documentFee: {
			filterType: 'like',
			filterValue: '',
		},
		status: {
			filterType: 'like',
			filterValue: '',
		}

	}

	const urlNext = process.env.REACT_APP_API_URL + 'dashboard/next-expired-documents'

	return (
		<>
			<div className='card card-custom'>
				<div className='card-header'>
					<div className="card-title">
						<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
						<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
							Expired Document List
						</span>
					</div>
				</div>

				<div className='card-body'>

					<DataTable url={url} columns={columns} filters={filters} />

				</div>
			</div>

      <div className='card card-custom'>
				<div className='card-header'>
					<div className="card-title">
						<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
						<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
              List of Expired Documents within Next 1 Month
						</span>
					</div>
				</div>

				<div className='card-body'>

					<DataTable url={urlNext} columns={columnsNext} filters={filtersNext} />

				</div>
			</div>
		</>
	)
}

export default Dashboard
