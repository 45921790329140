/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import showLoader from '../helpers/ShowLoader'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {getOptions, postFileOptions} from '../components/common/ApiService'
import {KTSVG} from '../../_metronic/helpers'
import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee, faSave } from '@fortawesome/free-solid-svg-icons'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import axios from 'axios'
import Button from '@mui/material/Button'

function DocumentAmendment(props) {
  const initialState = () => ({
    amendmentType: 'Amendment',
    amendmentNo: '',
    amendmentDate: '',
    idProjects: '',
    idDocMasters: '',
    documentNo: '',
    issueDate: '',
    expireDate: '',
    otherData: {},
  })

  let history = useHistory()
  const [formData, setFormData] = useState(initialState())
  const [amendmentType, setAmendmentType] = useState({
    id: 'Amendment',
    label: 'Amendment',
    value: 'Amendment',
  })
  const [document, setDocument] = useState('')
  const [dynamicFields, setDynamicFields] = useState([])
  const [docOtherFieldObject, setDocOtherFieldObject] = useState({})
  const [files, setFiles] = useState(null)
  const [amendmentTypeOption, setAmendmentTypeOption] = useState([
    {id: 'Amendment', label: 'Amendment', value: 'Amendment'},
    {id: 'Renew', label: 'Renew', value: 'Renew'},
  ])

  const amendmentLoadOptions = (inputValue, callback) => {
    const amendmentTypeArray = amendmentTypeOption.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    callback(amendmentTypeArray)
  }

  const getDocuments = (inputValue, callback) => {
    var api = process.env.REACT_APP_API_URL + 'amendment/documents?q=' + inputValue
    setTimeout(() => {
      fetch(api, getOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          setDocument('')

          const tempDocArray = resp.data.map(function (element) {
            return {
              ...element,
              id: element.idDocDocuments,
              label: element.docName,
              value: element.idDocDocuments,
            }
          })

          callback(tempDocArray)
        })
        .catch((error) => {
          callback(error)
          console.log(error, 'catch the hoop')
        })
    }, 500)
  }

  const handleAmendmentTypeChange = (amendmentType) => {
    setAmendmentType(amendmentType)
    setFormData({
      ...formData,
      amendmentType: amendmentType.id,
    })
  }

  const handleDocumentChange = (document) => {
    let dynamicFields = []
    if (document.otherFields !== undefined) {
      dynamicFields = JSON.parse(document.otherFields)
    }
    if (document.docOtherFields !== undefined) {
      setDocOtherFieldObject(JSON.parse(document.docOtherFields))
    }

    console.log(dynamicFields)
    console.log(document)

    setDocument(document)
    setDynamicFields(dynamicFields)
    setFormData({
      ...formData,
      idDocDocuments: document.idDocDocuments,
      idProjects: document.idProjects,
      idDocMasters: document.idDocMasters,
      amendmentNo: document.documentNo,
      documentNo: document.documentNo,
      issueDate: document.issueDate,
      expireDate: document.expireDate,
      documentFee: document.documentFee,
      documentLocation: document.documentLocation ?? '',
      otherData: JSON.parse(document.docOtherFields),
    })
  }

  const handleChangeStatus = ({meta, file}, status, fileWithMeta) => {
    //Latest all files
    setFiles(fileWithMeta)
  }

  const handleFormDataChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOtherDataChange = (event) => {
    setFormData({
      ...formData,
      otherData: {
        ...formData.otherData,
        [event.target.name]: event.target.value,
      },
    })
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        showLoader()
        const postFormData = new FormData()

        for (const key in formData) {
          if (key === 'otherData') {
            postFormData.append(key, JSON.stringify(formData[key]))
          } else {
            postFormData.append(key, formData[key])
          }
        }

        if (files !== null) {
          for (let i = 0; i < files.length; i++) {
            postFormData.append('files[]', files[i].file)
          }
        }

        var url = process.env.REACT_APP_API_URL + 'amendment/document/store'

        axios(postFileOptions(url, postFormData))
          .then((response) => {
            Swal.close()
            const successMessage = response.data.successMessage
            if(response.data.success===true){
              
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMessage,
              })

              setFormData(initialState())

              files
                .slice()
                .reverse()
                .forEach((f) => f.remove());
              
                history.push(`document-amendment-list`)
              
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: successMessage,
              })
            }
            
          })
          .catch((error) => {

            console.log('error = ', error)
            Swal.close()
            const errorsMessage = (error.response?.data) ? validationErrorMsg(error.response.data.errors).join('<br>') : 'Some thing went wrong!'
            Swal.fire({
              icon: 'error',
              title: 'Error',
              html: errorsMessage,
            })
          })
      } else {
        Swal.close()
      }
    })
  }

  const DocumentSingleValue = ({children, ...props}) => (
    <components.SingleValue {...props}>
      <span>{props.data.documentNo}</span>
    </components.SingleValue>
  )

  const documentDynamicFields = dynamicFields?.map((field, i) => {
    return (
      <div className='form-group row' key={`${i}`}>
        <label
          htmlFor={field.name}
          className='col-lg-4 col-form-label'
          style={{textAlign: 'right'}}
          key={`label${i}`}
        >
          {field.label} {field.validation ? <span className='required text-danger'></span> : ''}
        </label>
        <div className='col-lg-7' key={`row-${i}`}>
          {(() => {
            if (field.type === 'select') {
              return (
                <select
                  className='form-control form-control-sm'
                  name={field.name}
                  id={field.name}
                  onChange={handleOtherDataChange}
                  key={`select-${i}`}
                  value={formData.otherData[field.name] ?? ''}
                >
                  <option key={`option-${i}`}>Select {field.label}</option>
                  {field.options?.map((value, index) => (
                    <option value={value.label} key={i + '-' + index}>
                      {value.label}
                    </option>
                  ))}
                </select>
              )
            } else {
              return (
                <input
                  value={formData.otherData[field.name] ?? ''}
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  placeholder={'Enter ' + field.label}
                  className='form-control form-control-sm'
                  onChange={handleOtherDataChange}
                  key={`input-${i}`}
                />
              )
            }
          })()}
        </div>
      </div>
    )
  })

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <div className='card-title'>
          <KTSVG
            path='/media/icons/duotune/text/txt001.svg'
            className='svg-icon-1 svg-icon-primary'
          />
          <span className='text fw-bolder' style={{marginLeft: '10px'}}>
            Document Amendment
          </span>
        </div>
      </div>
      <div className='card-body'>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group row'>
                <label
                  htmlFor='idProjects'
                  className='col-lg-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Amendment Type<span className='required text-danger'></span>
                </label>
                <div className='col-lg-7'>
                  <AsyncSelect
                    value={amendmentType}
                    name='amendmentType'
                    id='amendmentType'
                    cacheOptions
                    defaultOptions={amendmentTypeOption}
                    loadOptions={amendmentLoadOptions}
                    placeholder='Select Amendment Type'
                    onChange={handleAmendmentTypeChange}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='idDocDocuments'
                  className='col-lg-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Select Document<span className='required text-danger'></span>
                </label>
                <div className='col-lg-7'>
                  <AsyncSelect
                    value={document}
                    name='idDocDocuments'
                    id='idDocDocuments'
                    cacheOptions
                    defaultOptions
                    loadOptions={getDocuments}
                    placeholder='Select Document'
                    onChange={handleDocumentChange}
                    components={{SingleValue: DocumentSingleValue}}
                    getOptionLabel={(option) => (
                      <>
                        <KTSVG
                          path='/media/svg/icons/Files/File.svg'
                          className='svg-icon-2 svg-icon-primary'
                        />
                        <span> {option.docName} </span>
                        <br></br>
                        <KTSVG
                          path='/media/svg/icons/Communication/Outgoing-box.svg'
                          className='svg-icon-2 svg-icon-warning'
                        />
                        <span> {option.documentNo} </span>
                        <br></br>
                        <KTSVG
                          path='/media/svg/icons/General/Clipboard.svg'
                          className='svg-icon-2 svg-icon-success'
                        />
                        <span> {option.project.projectName} </span>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='amendmentNo'
                  className='col-lg-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Amendment No<span className='required text-danger'></span>
                </label>
                <div className='col-lg-7'>
                  <input
                    type='text'
                    name='amendmentNo'
                    id='amendmentNo'
                    className='form-control form-control-sm'
                    onChange={handleFormDataChange}
                    value={formData.amendmentNo}
                    disabled={formData.amendmentType === 'Amendment'}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='amendmentDate'
                  className='col-lg-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Amendment Date<span className='required text-danger'></span>
                </label>
                <div className='col-lg-7'>
                  <input
                    value={formData.amendmentDate}
                    type='date'
                    name='amendmentDate'
                    id='amendmentDate'
                    className='form-control form-control-sm'
                    onChange={handleFormDataChange}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='issueDate'
                  className='col-lg-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Issue Date<span className='required text-danger'></span>
                </label>
                <div className='col-lg-7'>
                  <input
                    value={formData.issueDate}
                    type='date'
                    name='issueDate'
                    id='issueDate'
                    className='form-control form-control-sm'
                    onChange={handleFormDataChange}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='expireDate'
                  className='col-lg-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Expire Date
                </label>
                <div className='col-lg-7'>
                  <input
                    value={formData.expireDate}
                    type='date'
                    name='expireDate'
                    id='expireDate'
                    className='form-control form-control-sm'
                    onChange={handleFormDataChange}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='documentFee'
                  className='col-lg-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Document Fee
                </label>
                <div className='col-lg-7'>
                  <input
                    type='number'
                    min='0'
                    step='any'
                    name='documentFee'
                    id='documentFee'
                    className='form-control form-control-sm'
                    onChange={handleFormDataChange}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='documentLocation'
                  className='col-lg-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Document Location
                </label>
                <div className='col-lg-7'>
                  <input
                    type='text'
                    name='documentLocation'
                    id='documentLocation'
                    className='form-control form-control-sm'
                    onChange={handleFormDataChange}
                  />
                </div>
              </div>

              {documentDynamicFields}

              <div className='form-group row'>
                <div className='col-4'></div>
                <div className='col-6'>
                  <Button variant='contained' type='submit' size='small'>
                    <i className='fa fa-save me-2 text-white'></i>
                    Save
                  </Button>
                </div>
              </div>
              {/* <pre>
                {JSON.stringify(formData, null, 2)}
                <br></br>
                ========================================
              </pre>
              <pre>
                {JSON.stringify(docOtherFieldObject, null, 2)}
                <br></br>
                ========================================
              </pre> */}
            </div>
            <div className='col-4'>
              <Dropzone
                getUploadParams=''
                onChangeStatus={handleChangeStatus}
                accept='image/*,audio/*,application/pdf'
              />
            </div>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  )
}

export default DocumentAmendment
