import React, {useState} from 'react'
import DataTable from '../components/DtTable'
import {KTSVG} from '../../_metronic/helpers'
import DocumentMaster from '../components/DocumentMaster'
import DocumentMasterSetup from '../components/DocumentMasterSetup'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';

function ShowSetupDocumentModal(props) {
  
  let otherFields = [];
  if(props.data.otherFields!==undefined){
    otherFields = JSON.parse(props.data.otherFields)
    console.log(otherFields);
  }

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{props.data.docName} Setup Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {otherFields?.map((field, i) => {
            return (
              <Form.Group className="mb-3" controlId={i} key={i}>
                <Form.Label>{field.label}</Form.Label>
                {(() => {
                  if(field.type==='select'){
                    return (
                      <Form.Select aria-label={'Default '+field.label} className='form-control-sm'>
                        <option>{'Select '+field.label}</option>
                        {field.options.map((value, index) => <option value={value.label} >{value.label}</option>)}
                      </Form.Select>
                    )
                  }else{
                    return (
                      <Form.Control type={field.type} placeholder={'Enter '+field.label} className='form-control-sm'/>
                    )
                  }
                })()}
                
                {/* <Form.Control type={field.type} placeholder={'Enter '+field.label} /> */}
              </Form.Group>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export const Document = () => {
  const [newData, setNewData] = useState('')
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [documentInfo, setDocumentInfo] = useState({});
  const [setupModalShow, setSetupModalShow] = useState(false)

  const onCreate = ({id, name}) => {
    setNewData({id: id, name: name})
  }

  const columns = [
    {value: 'id', title: 'Id', style: {textAlign: 'center'}},
    {value: 'docName', title: 'Doc Name'},
    {value: 'status', title: 'Status', style: {textAlign: 'center'}},
    {
      value: 'action',
      title: 'Action',
      style: {textAlign: 'center'},
      actionsComponent: (row) => {
        return (
          <>
            <button className='btn btn-light-primary btn-sm' onClick={() => onAction(row)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
              Edit
            </button>
            <button className='btn btn-light-primary btn-sm' onClick={() => viewModalShow(row)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
              View
            </button>
            <button className='btn btn-light-primary btn-sm' onClick={() => viewSetupModalShow(row)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
              Setup Document
            </button>
          </>
        )
      },
    },
  ]

  const filters = {
    id: {
      filterType: 'like',
      filterValue: '',
    },
    docName: {
      filterType: 'like',
      filterValue: '',
    },
    status: {
      filterType: 'like',
      filterValue: '',
    },
  }

  const url = process.env.REACT_APP_API_URL + 'doc-master/datatable'

  const onAction = (data) => {
    //console.log(data)
    setId(data.id)
    setName(data.shiftName)
  }

  const viewModalShow = (row) => {
    console.log(row);

    setDocumentInfo(row);
    setModalShow(true);
  }
  const viewSetupModalShow = (row) => {

    setDocumentInfo(row);
    setSetupModalShow(true);
  }


  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Document Master
            </span>
          </div>
        </div>

        <div className='card-body'>
          <DocumentMaster onCreate={onCreate} id={id} name={name} />
          <DataTable url={url} columns={columns} filters={filters} newData={newData} />
        </div>
      </div>
      <ShowSetupDocumentModal data={documentInfo} show={modalShow} onHide={() => setModalShow(false)} />
      <DocumentMasterSetup data={documentInfo} show={setupModalShow} onHide={() => setSetupModalShow(false)} />
      
    </>
  )
}

export default Document
