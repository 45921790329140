/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  getOptions,
  postFileOptions,
  postOptions,
  deleteOptions,
} from '../components/common/ApiService'
import {KTSVG} from '../../_metronic/helpers'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import showLoader from '../helpers/ShowLoader'
import {Button, Modal} from 'react-bootstrap-v5'
import { Link } from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css'
import DocumentHistory from '../components/DocumentHistory'
import UserPagePermission from '../components/UserPagePermission'

function DocumentAmendmentDetails(props) {
  const [details, setDetails] = useState({})

  let history = useHistory()
  const [dynamicFields, setDynamicFields] = useState([])
  const hasDeletePermission = UserPagePermission('/document-amendment-delete')
  const hasApprovePermission = UserPagePermission('/document-amendment-approve')
  const hasDocFileUploadPermission = UserPagePermission('/document-file-upload')
  const hasDocFileDeletePermission = UserPagePermission('/document-file-delete')
  const filehost = process.env.REACT_APP_AWS_URL + 'document_portal/document'

  useEffect(() => {
    getDocumentDetails(props.match.params.id)
  }, [])

  const getDocumentDetails = (idDocDocumentAmendments) => {
    showLoader()
    fetch(process.env.REACT_APP_API_URL + 'amendment/document/' + idDocDocumentAmendments, getOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        Swal.close()
        setDetails(resp)
        let dynamicFields = []
        if (resp.document.document_master.otherFields !== undefined) {
          dynamicFields = JSON.parse(resp.document.document_master.otherFields)
        }
        setDynamicFields(dynamicFields)
      })
      .catch((error) => {
        Swal.close()
        console.log(error, 'catch the hoop')
      })
  }

  const approve = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ec008c',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Approve it!',
    }).then((result) => {
      if (result.value) {
        showLoader()
        const formData = {
          idDocDocumentAmendments: props.match.params.id,
        }
        const documentOptions = postOptions(formData)

        fetch(process.env.REACT_APP_API_URL + 'amendment/document/approve', documentOptions)
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            Swal.close()
            if (resp.success === true) {
              const successMsg = resp.successMessage
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMsg,
              })
              getDocumentDetails(props.match.params.id)
            } else if(resp.success === false){
                Swal.fire({
                  icon: 'danger',
                  title: 'Error',
                  text: resp.successMessage,
                })
            }else {
              const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
              Swal.fire({
                icon: 'error',
                title: 'Error',
                html: errorsMessage,
              })
            }
          })
          .catch((error) => {
            Swal.close()
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  const deleteDocumentAmendment = () => {
    let id = props.match.params.id
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this document amendment!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ec008c',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      if (result.value) {
        showLoader()

        fetch(process.env.REACT_APP_API_URL + 'amendment/document/delete/' + id, deleteOptions())
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            Swal.close()
            if (resp.success === true) {
              const successMsg = resp.successMessage
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMsg,
              }).then(function () {
                history.push(`/document-amendment-list`)
              })
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: resp.successMessage,
              })
            }
            
          })
          .catch((error) => {
            Swal.close()
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <div className='card-title'>
          <KTSVG
            path='/media/svg/icons/Code/Info-circle.svg'
            className='svg-icon-1 svg-icon-primary'
          />
          <span className='text fw-bolder' style={{marginLeft: '10px'}}>
            Document Amendment/Renew Information
          </span>
        </div>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-6'>
            <table className='table table-bordered table-hover'>
              <tbody>
                <tr>
                  <th>Business Unit</th>
                  <td>{details?.document?.project?.projectName}</td>
                </tr>
                <tr>
                  <th>Document</th>
                  <td>{details?.document?.document_master?.docName}</td>
                </tr>
                <tr>
                  <th>Document No</th>
                  <td>{details?.documentNo}</td>
                </tr>
                <tr>
                  <th>Issue Date</th>
                  <td>{details?.issueDate}</td>
                </tr>
                <tr>
                  <th>Expire Date</th>
                  <td>{details?.expireDate}</td>
                </tr>
                <tr>
                  <th>Document Fee</th>
                  <td>{details?.documentFee}</td>
                </tr>
                <tr>
                  <th>Document Location</th>
                  <td>{details?.documentLocation}</td>
                </tr>
                {dynamicFields.map((field, i) => {
                  let otherData = JSON.parse(details.otherFields)
                  return (
                    <tr key={i}>
                      <th>{field?.label}</th>
                      <td>{otherData[field.name]}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='col-6'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>Files</div>
              </div>

              <div className='card-body'>
                <div className='row mt-2'>
                  <div className='col-12'>
                    <table className='table table-bordered table-hover'>
                      <thead>
                        <tr>
                          <th>SL</th>
                          <th>File</th>
                          <th>Size</th>
                        </tr>
                      </thead>
                      <tbody>
                        {details?.files?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <a
                                href={`${filehost}/${item.fileName}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-decoration-none'
                              >
                                {item.originalFile}
                              </a>
                            </td>
                            <td>{(item.fileSize / 1024 / 1024).toFixed(2)} MB</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          
              <div className='col-12 text-center'>
                <Link to={`/document-amendment-list`}>
                    <Button variant="info" size='sm' className='me-2'><i className='fa fa-arrow-left me-2 text-white'></i>Back to Amendment List</Button>
                </Link>
                <Link to={`/document-details/${details.id_doc_documents}`}>
                    <Button variant="info" size='sm' className='me-2'><i className='fa fa-arrow-left me-2 text-white'></i>Back to Document Details</Button>
                </Link>
                {details?.amendmentStatus === 'created' && (
                 <>
                  {hasApprovePermission === 1 && (
                    <button
                      type='primary'
                      className='btn btn-sm btn-primary me-2'
                      onClick={() => approve()}
                    >
                      <span>
                        <i className='fa fa-check'></i> Approve
                      </span>
                    </button>
                  )}
                  {hasDeletePermission === 1 && (
                    <Button variant='danger' size='sm' onClick={() => deleteDocumentAmendment()}>
                      <i className='fa fa-trash'></i> Delete
                    </Button>
                  )}
                  </>
                )}
              </div>
            
        </div>
      </div>
    </div>
  )
}

export default DocumentAmendmentDetails
