import React, {useState, useEffect, useContext} from 'react'
import Swal from 'sweetalert2'
import {KTSVG} from '../../_metronic/helpers'
import {getOptions, postOptions} from '../components/common/ApiService'
import showLoader from '../helpers/ShowLoader'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import AsyncSelect from 'react-select/async'
import Checkbox from '@mui/material/Checkbox'
import Button from 'react-bootstrap/Button'
import { AppContext } from '../context/AppContext'


export const DocumentAssign = () => {
  const [documents, setDocuments] = useState([])
  const [project, setProject] = useState('')
  const [formData, setFormData] = useState({
    idProjects: '',
    documents: [],
  })

  const {capitalizeFirst} = useContext(AppContext)

//   useEffect(() => {
//     getBusinessUnits()
//   }, [])

  const getBusinessUnits = (inputValue, callback) => {

    if(!inputValue){
        var api =  process.env.REACT_APP_API_URL + 'get_business_units';
    }
    else{
        api = process.env.REACT_APP_API_URL + 'get_business_units?q=' + inputValue
    }
    setTimeout(() => {
      fetch(api, getOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {
              ...element,
              id: element.idProjects,
              label: element.projectName,
              value: element.idProjects,
            }
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    }, 1000)
  }

  const getAssignDocuments = (idProjects) => {
    showLoader()
    var api = process.env.REACT_APP_API_URL + 'document/assign/' + idProjects
    fetch(api, getOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        Swal.close()
        setDocuments(resp.data)
      })
      .catch((error) => {
        Swal.close()
        console.log(error, 'catch the hoop')
      })
  }

  const handleProjectChange = (project) => {
    getAssignDocuments(project.idProjects)
    setProject(project)
    setFormData({
      ...formData,
      idProjects: project.idProjects,
    })
  }

  const handleChange = (e) => {
		let isChecked = e.target.checked;
		const documentState = documents.map(obj => {
			if (Number(obj.idDocMasters) === Number(e.target.value)) {
				return {...obj, assignDocumentCount: isChecked ? 1 : 0};
			}

			return obj;
		});

		setDocuments(documentState);

  }

  const handleSubmit = (evt) => {
    evt.preventDefault()
	let documentArr = [];
	
	documents.forEach(element => {
		if (element.assignDocumentCount) {
			documentArr.push(element.idDocMasters);
		}
	})

       
    const postData = {'idProjects':formData.idProjects,documents:documentArr}

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to assign document!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        showLoader()
		
        fetch(process.env.REACT_APP_API_URL + 'document/assign', postOptions(postData))
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            console.log(resp)
            Swal.close()
            if (resp.success === true) {
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Document assigned successfully',
              })
            } else {
              const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
              Swal.fire({
                icon: 'error',
                title: resp.message,
                html: errorsMessage,
              })
            }
          })
          .catch((error) => {
            Swal.close()
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Something went wrong!',
            })
          })
      }
    })
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Assign Document
            </span>
          </div>
        </div>

        <div className='card-body'>
          <div className='form-group row'>
            <label
              htmlFor='idProjects'
              className='col-lg-4 col-form-label'
              style={{textAlign: 'right'}}
            >
              Business Unit<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <AsyncSelect
                value={project}
                name='idProjects'
                id='idProjects'
                defaultOptions
                loadOptions={getBusinessUnits}
                placeholder='Select Business Unit'
                onChange={handleProjectChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mt-5'>
              <table className='table table-bordered table-hover'>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Document Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.docName}</td>
                      <td>{capitalizeFirst(item.status)}</td>
                      <td>
                        <Checkbox
                          onChange={handleChange}
                          value={item.idDocMasters}
                          checked={item.assignDocumentCount===1 ? true : false}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='card-footer text-center'>
          <Button size='small' variant='primary' onClick={handleSubmit}>
            <i className='fa fa-save'></i>Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default DocumentAssign
