
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import Dashboard from '../pages/Dashboard';
import PageList from '../pages/PageList';
import PageManagement from '../pages/PageManagement';
import RoleManagement from '../pages/RoleManagement';
import UserRolePermission from '../pages/UserRolePermission';

import DocumentMaster from '../pages/DocumentMaster';
import EmailManagement from '../pages/EmailManagement';
import DocumentCreate from '../pages/DocumentCreate';
import DocumentList from '../pages/DocumentList';
import DocumentDetails from '../pages/DocumentDetails';
import DocumentAssign from '../pages/DocumentAssign';
import DocumentAmendment from '../pages/DocumentAmendment';
import BuWiseDocument from '../pages/BuWiseDocument';
import DocumentAmendmentList from '../pages/DocumentAmendmentList';
import DocumentAmendmentDetails from '../pages/DocumentAmendmentDetails';
import Project from '../pages/Project';

export function PrivateRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/setup/doc-master' component={DocumentMaster} />
        <Route path='/setup/emails' component={EmailManagement} />
        <Route path='/setup/doc-assign' component={DocumentAssign} />
        <Route path='/setup/projects' component={Project} />
        
        <Route path='/document-list' component={DocumentList} />
        <Route path='/document-create' component={DocumentCreate} />
        <Route path='/document-details/:id' component={DocumentDetails} />
        <Route path='/document-bu-wise' component={BuWiseDocument} />
        <Route path='/document-amendment-create' component={DocumentAmendment} />
        <Route path='/document-amendment-list' component={DocumentAmendmentList} />
        <Route path='/document-amendment-details/:id' component={DocumentAmendmentDetails} />

        <Route path='/accesscontrol-rolemanagement' component={RoleManagement} />
        <Route path='/accesscontrol-userrolepermission' component={UserRolePermission} />
        <Route path='/accesscontrol-page-list' component={PageList} />
        <Route path='/accesscontrol-pagemanagement' component={PageManagement} />

        <Redirect from='/auth' to='/dashboard' />
				<Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
