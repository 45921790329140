/* eslint-disable no-unused-vars */
import {useState, createContext, useEffect} from 'react'

export const AppContext = createContext()

function AppContextProvider(props) {
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const value = {capitalizeFirst}

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
}

export default AppContextProvider
