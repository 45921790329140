/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import showLoader from "../helpers/ShowLoader"
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getToken, getOptions, postFileOptions } from '../components/common/ApiService'
import { KTSVG } from '../../_metronic/helpers'
import AsyncSelect from 'react-select/async'
import validationErrorMsg from "../helpers/ValidationErrorMsg"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee, faSave } from '@fortawesome/free-solid-svg-icons'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import axios from 'axios';
import Button from '@mui/material/Button';
import { select } from 'redux-saga/effects'

function DocumentCreate(props) {

    const initialState = () => ({
		idProjects: '',
		idDocMasters: '',
		documentNo: '',
		issueDate: '',
		expireDate: '',
        otherData:{}
	})

    let history = useHistory()
    const [formData, setFormData] = useState(initialState());
    const [token, setToken] = useState(null);
    const [project, setProject] = useState('')
    const [document, setDocument] = useState('')
    const [dynamicFields, setDynamicFields] = useState([])
    const [files, setFiles] = useState(null);
    const [documentDefaultOption, setDocumentDefaultOption] = useState([]);

    useEffect(() => {
        setToken(getToken);
    });

    
    const getBusinessUnits = (inputValue, callback) => {
        var api = process.env.REACT_APP_API_URL + 'get_business_units?q=' + inputValue
        setTimeout(() => {
            fetch(api, getOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        return {
                            ...element,
                            id: element.idProjects,
                            label: element.projectName,
                            value: element.idProjects,
                        }
                    })
                    callback(tempArray)
                })
                .catch((error) => {
                    console.log(error, 'catch the hoop')
                })
        }, 500)
    }

    const getDocumentMasters = (inputValue, callback) => {
        const idProject = project.idProjects ?? 0;
        var api = process.env.REACT_APP_API_URL + 'document/assigned-masters/'+idProject+'?q=' + inputValue
            fetch(api, getOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setDocument('')
                    const tempDocArray = resp.data.map(function (element) {
                        return {
                            ...element,
                            id: element.idDocMasters,
                            label: element.docName,
                            value: element.idDocMasters,
                        }
                    })

                    callback(tempDocArray)
                })
                .catch((error) => {
                    callback(error);
                    console.log(error, 'catch the hoop')
                })
        }

        const getDefaultOptionDocumentMasters = (idProjects) => {
            var api = process.env.REACT_APP_API_URL + 'document/assigned-masters/'+idProjects
            fetch(api, getOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setDocument('')
                    // console.log(resp.data);
                    const tempDocArray = resp.data.map(function (element) {
                        return {
                            ...element,
                            id: element.idDocMasters,
                            label: element.docName,
                            value: element.idDocMasters,
                        }
                    })
                    setDocumentDefaultOption(tempDocArray);
                
                })
                .catch((error) => {
                
                    console.log(error, 'catch the hoop')
                })
        }

    const handleProjectChange = (project) => {
       
        setProject(project)
        getDefaultOptionDocumentMasters(project.idProjects);
        setFormData({
            ...formData,
            idProjects: project.idProjects,
            idDocMasters: '',
        });
    }

    const handleDocumentChange = (document) => {
       
        let dynamicFields = []
        if (document.otherFields !== undefined) {
            dynamicFields = JSON.parse(document.otherFields)
        }
        setDocument(document)
        setDynamicFields(dynamicFields)
        setFormData({
            ...formData,
            idDocMasters: document.idDocMasters,
        });
    }

    const handleChangeStatus = ({ meta, file }, status, fileWithMeta) => {
        //Latest all files
        setFiles(fileWithMeta)
    }

    const handleFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleOtherDataChange = (event) => {
        
        setFormData({
            ...formData,
            otherData:{
                ...formData.otherData,
                [event.target.name]: event.target.value,
            }
            
        });
    };
    

    const handleSubmit = (evt) => {
        evt.preventDefault()
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                showLoader()
                const postFormData = new FormData();
                
                for (const key in formData) {
                    if(key==='otherData'){
                        postFormData.append(key, JSON.stringify(formData[key])); 
                    }else{
                        postFormData.append(key, formData[key]);
                    }
                    
                }

                if(files!==null){
                    for (let i = 0; i < files.length; i++) {
                        postFormData.append("files[]", files[i].file);
                    }
                }
                

                var url = process.env.REACT_APP_API_URL + "document/store";
                
                axios(postFileOptions(url, postFormData))
                    .then(response => {
                        Swal.close();
                        const successMessage = response.data.successMessage;
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: successMessage
                        })

                        setFormData(initialState());

                        files.slice().reverse().forEach(f => f.remove())
                        history.push(`document-create`);
                    })
                    .catch((error) => {
                        Swal.close();
                        const errorsMessage = validationErrorMsg(error.response.data.errors).join("<br>");
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            html: errorsMessage,
                        })
                    });

            }else {
				Swal.close();
			}
        })

    }

    return (
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/icons/duotune/text/txt001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        Document Create
                    </span>
                </div>
            </div>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='form-group row'>
                                <label
                                    htmlFor='idProjects'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Business Unit<span className='required text-danger'></span>
                                </label>
                                <div className='col-lg-7'>
                                    <AsyncSelect
                                        value={project}
                                        name='idProjects'
                                        id='idProjects'
                                        defaultOptions
                                        loadOptions={getBusinessUnits}
                                        placeholder='Select Business Unit'
                                        onChange={handleProjectChange}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label
                                    htmlFor='idDocMaster'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Document Name<span className='required text-danger'></span>
                                </label>
                                <div className='col-lg-7'>
                                    <AsyncSelect
                                        value={document}
                                        name='idDocMaster'
                                        id='idDocMaster'
                                        defaultOptions={documentDefaultOption}
                                        loadOptions={getDocumentMasters}
                                        placeholder='Select Document'
                                        onChange={handleDocumentChange}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label
                                    htmlFor='documentNo'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Document No<span className='required text-danger'></span>
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        type='text'
                                        name='documentNo'
                                        id='documentNo'
                                        className='form-control form-control-sm'
                                        onChange={handleFormDataChange}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label
                                    htmlFor='issueDate'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Issue Date<span className='required text-danger'></span>
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        type='date'
                                        name='issueDate'
                                        id='issueDate'
                                        className='form-control form-control-sm'
                                        onChange={handleFormDataChange}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label
                                    htmlFor='expireDate'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Expire Date
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        type='date'
                                        name='expireDate'
                                        id='expireDate'
                                        className='form-control form-control-sm'
                                        onChange={handleFormDataChange}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label
                                    htmlFor='documentFee'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Document Fee
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        type='number'
                                        min='0'
                                        step='any'
                                        name='documentFee'
                                        id='documentFee'
                                        className='form-control form-control-sm'
                                        onChange={handleFormDataChange}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label
                                    htmlFor='documentLocation'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Document Location
                                </label>
                                <div className='col-lg-7'>
                                    <input
                                        type='text'
                                        name='documentLocation'
                                        id='documentLocation'
                                        className='form-control form-control-sm'
                                        onChange={handleFormDataChange}
                                    />
                                </div>
                            </div>
                            
                            {dynamicFields?.map((field, i) => {
                                return (
                                    <div className='form-group row' key={i}>
                                        <label
                                            htmlFor={field.name}
                                            className='col-lg-4 col-form-label'
                                            style={{ textAlign: 'right' }}
                                        >
                                            {field.label}{' '}
                                            {field.validation ? <span className='required text-danger'></span> : ''}
                                        </label>
                                        <div className='col-lg-7'>
                                            {(() => {
                                                if(field.type==='select'){
                                                    return (
                                                        <select className='form-control form-control-sm' name={field.name} id={field.name} onChange={handleOtherDataChange}>
                                                            <option>Select {field.label}</option>
                                                            {field.options.map((value, index) => <option value={value.label} >{value.label}</option>)}
                                                        </select>
                                                    )
                                                    
                                                }else{
                                                    return (
                                                        <input
                                                            type={field.type}
                                                            name={field.name}
                                                            id={field.name}
                                                            placeholder={'Enter ' + field.label}
                                                            className='form-control form-control-sm'
                                                            onChange={handleOtherDataChange}
                                                        />
                                                    )
                                                }
                                            })()}
                                            
                                        </div>
                                    </div>
                                )
                            })}

                            <div className='form-group row'>
                                <div className='col-4'></div>
                                <div className='col-6'>
                                    <Button variant="contained" type='submit'  size='small'>
                                        <i className='fa fa-save me-2 text-white'></i>  
                                        Save
                                    </Button>
                                </div>
                            </div>
                            {/* <pre>
                                {JSON.stringify(formData, null, 2)}
                                <br></br>
                                ========================================
                            </pre> */}
                        </div>
                        <div className='col-4'>
                            <Dropzone
                                getUploadParams=''
                                onChangeStatus={handleChangeStatus}
                                accept='image/*,audio/*,application/pdf'
                            />
                        </div>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    )
}

export default DocumentCreate
