/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {getRequestOptions} from './GetToken'
import {postRequestOptions} from '../helpers/Fetchwrapper'

function DocumentMaster(props) {
  let history = useHistory()
  const [id, setId] = useState('')
  const [docName, setDocName] = useState('')
  const [status, setStatus] = useState('active')

  const myRef = useRef()

  useEffect(() => {
    //setDocName(props.name);
    if (id !== props.id || docName !== props.name) {
      setId(props.id)
      getDocumentMasterDetails(props.id)
      window.scrollTo(0, 0)
      myRef.current.focus()
    }
  }, [props.id, props.name])

  const getDocumentMasterDetails = (idDocMasters) => {
    fetch(process.env.REACT_APP_API_URL + 'doc-master/details/' + idDocMasters, getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        setDocName(resp.doc_name)
        setStatus(resp.status)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  const statusType = [
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'},
  ]

  const handleSubmit = (evt) => {
    evt.preventDefault()

    if (id !== '') {
      var text = 'You want to update this Document Master!'
    } else {
      text = 'You want to add this Document Master!'
    }

    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        const jwt = () => {
          return JSON.parse(localStorage.getItem('MyToken'))
        }
        const formData = {
          id: id,
          docName: docName,
          status: status,
        }

        const requestOptions = postRequestOptions(formData)

        if (id !== '') {
          var apiEnd = 'doc-master/update'
        } else {
          apiEnd = 'doc-master/store'
        }

        fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            console.log(resp)

            if (resp.success === true) {
              if (id !== '') {
                var successMsg = [`Doc '${resp.data.name}' updated`]
              } else {
                successMsg = [`Doc '${resp.data.name}' added`]
              }

              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMsg,
              })
              setId('')
              setDocName('')
              setStatus('active')
              props.onCreate({id: resp.data.id, name: resp.data.name})
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                html: resp.errorMessage,
              })
            }
          })
          .catch((error) => {
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  return (
    <div className='card-body'>
      <div className='ml-12'>
        <form onSubmit={handleSubmit}>
          <div className='form-group row'>
            <label
              htmlFor='shiftName'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Document Name<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='docName'
                id='docName'
                ref={myRef}
                value={docName}
                onChange={(e) => setDocName(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='status'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Status<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <select
                className='form-select form-select-sm'
                id='status'
                name='status'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                {statusType.map(function (item, id) {
                  return (
                    <option key={id} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'></div>
            <div className='col-6'>
              <input
                type='submit'
                className='btn btn-sm btn-success mt-2'
                value={id !== '' ? 'Update' : 'Save'}
              />
            </div>
          </div>
          {/* <pre>
            {JSON.stringify(shiftName, null, 2)}
            {JSON.stringify(status, null, 2)}
          </pre> */}
        </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default DocumentMaster
