import Swal from 'sweetalert2'
function  showLoader(errors){
            
    Swal.fire({
        html: 'Processing data.... <i class="fas fa-spinner fa-spin"></i>',
        showConfirmButton: false,
        allowOutsideClick: false,
    });

}

export default showLoader;