import React, {useState} from 'react'
import DataTable from '../components/DtTable'
import {KTSVG} from '../../_metronic/helpers'
import EmailMaster from '../components/EmailMaster'

export const EmailManagement = () => {
  const [newData, setNewData] = useState('')
  const [id, setId] = useState('')
  const [name, setName] = useState('')

  const onCreate = ({id, name}) => {
    setNewData({id: id, name: name})
  }

  const columns = [
    {value: 'id', title: 'Id', style: {textAlign: 'center'}},
    {value: 'name', title: 'Name'},
    {value: 'email', title: 'Email'},
    {value: 'status', title: 'Status', style: {textAlign: 'center'}},
    {
      value: 'action',
      title: 'Action',
      style: {textAlign: 'center'},
      actionsComponent: (row) => {
        return (
          <>
            <button className='btn btn-light-primary btn-sm' onClick={() => onAction(row)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
              Edit
            </button>
          </>
        )
      },
    },
  ]

  const filters = {
    id: {
      filterType: 'like',
      filterValue: '',
    },
    name: {
      filterType: 'like',
      filterValue: '',
    },
    email: {
      filterType: 'like',
      filterValue: '',
    },
    status: {
      filterType: 'like',
      filterValue: '',
    },
  }

  const url = process.env.REACT_APP_API_URL + 'email-management/datatable'

  const onAction = (data) => {
    //console.log(data)
    setId(data.id)
    setName(data.name)
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Email Management
            </span>
          </div>
        </div>

        <div className='card-body'>
          <EmailMaster onCreate={onCreate} id={id} name={name} />
          <DataTable url={url} columns={columns} filters={filters} newData={newData} />
        </div>
      </div>
    </>
  )
}

export default EmailManagement
